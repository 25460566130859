.landing-page {
    min-height: 100vh;
    display: flex;
    flex: 0 1 auto;
    justify-content: center;
    margin: 0 auto;
}

.main {
    display: flex;
    flex: 1 0 auto;
    flex-direction: column;
    justify-content: center;
    padding: 3.25rem 1.5rem 1rem;
    text-align: center;
}

.intro-wrapper {
    flex: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    margin-top: 20%;
    margin: 0 auto;
    width: 80%;
}

.intro-name {
    font-family: 'FjallaOne', sans-serif;
    font-size: 4rem;
    font-weight: bold;
    line-height: 4rem;
    padding-bottom: 1rem;
    animation-name: pulse;
    animation-duration: 5s;
  animation-fill-mode: both;
  animation-iteration-count: infinite;
}

.tagline {
    display: none;
    font-size: 1.375rem;
        margin: 1.25rem 0;
        font-weight: 300;
}

.tagline-mobile {
    display: block;
    margin: 1.25rem 0;
    font-weight: 300;
}

.landing-container {
    width: 100%;
    margin: 0 auto;
}

.bitmoji {
    margin-top: 5rem;
    margin-bottom: -5rem;
}

@keyframes pulse {
    0% {transform: scale(1);}
    50% {transform: scale(1.1);}
    100% {transform: scale(1);}
}

.contact-icons {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    
}

.icon-margin {
    margin-right: 4rem;
    margin-left: 4rem;
    margin-top: 1rem;
    margin-bottom: 1rem;
}

.icon {
    color: black;
    -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-timing-function: ease-in-out;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
  -webkit-animation-iteration-count: infinite;
}

.tag {
    font-size: 1.375rem;
}

@keyframes bounce {
	0%, 100%, 20%, 50%, 80% {
		-webkit-transform: translateY(0);
		-ms-transform:     translateY(0);
		transform:         translateY(0)
	}
	40% {
		-webkit-transform: translateY(-30px);
		-ms-transform:     translateY(-30px);
		transform:         translateY(-30px)
	}
	60% {
		-webkit-transform: translateY(-15px);
		-ms-transform:     translateY(-15px);
		transform:         translateY(-15px)
	}
}

.icon:hover {
  cursor: pointer;
  animation-name: bounce;
  -moz-animation-name: bounce;
}

@media (min-width: 325px ){
    .intro-name {
        font-size: 5rem;
    }
}

@media (min-width: 480px ){
    .tagline-mobile {
        display: none;
    }

    .tagline {
        display: block;
    }
}

