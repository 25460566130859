.about-page {
    width: 90%;
    margin: 0 auto;
    min-height: 100vh;
    display: flex;
    flex: 0 1 auto;
    justify-content: center;
}
.about-wrapper {
    flex: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    margin: 0 auto;
}

.about-title {
    font-family: 'FjallaOne', sans-serif;
    font-size: 3.5rem;
    font-weight: bold;
    line-height: 4rem;
    padding-bottom: 1rem;
    text-align: left;
    margin-top: 4rem;
}

.about-container {
    margin: 0 auto;
    margin-top: 7%;

}

.about-content {
    text-align: left;
}

.skills-content {
    display: flex;
    flex-flow: column wrap;
    height: 20rem;
    align-content: center;
    margin-top: 10%;
}

.tech-icon {
    margin-left: .875rem;
}

.about-text {
    font-size:  1.25rem;
}

@media (min-width: 735px) {

    .about-wrapper {
        width: 80%;
    }


    .about-title {
        margin-top: 0;
    }

    .about-container {
        width: 100%;
    }

    .about-text {
        font-size: 1.125rem;
    }

    li {
        font-size: 1.125rem;
        margin-left: 2em;
    }

    .skills-content {
        display: inline-flex;
        flex-flow: wrap;
        justify-content: space-evenly;
        height: 4em;
        width: 100%;
    }

    .tech-icon {
       margin-left: 0;
    }


    
    
}