nav {
    position: fixed !important;
    top: 0;
    width: 100%;
    margin: 0 auto;
    height: 5rem;
    display: flex;
    align-items: center;
    background-color: rgba(234, 234, 234, 1);
    justify-content: center;
    z-index: 2;
}

.built-with {
    display: none
}

.built-with-wrapper {
    float: left;
    width: 100%;
    padding: 0.25rem;
    position: fixed;
    bottom: 99.7%;
    left: .5%;
    z-index: 3;

}

.menu {
    margin-right: 1rem;
    display: flex;
    flex: 1;
    justify-content: flex-end;
}

.menu__item {
    padding: 0.25rem;
    margin: 0 .35rem;
    font-size: 1.5rem;
    font-weight: 400;
    border-bottom: 2px solid transparent;
}

.menu__item:hover {
    cursor: pointer;
    color : #fff;  
  -webkit-transition: color 1s ease;
  -moz-transition: color 1s ease;
  -ms-transition: color 1s ease;
  -o-transition: color 1s ease;
  transition: color 1s ease;
}

.react-icon {
    vertical-align: middle;
}

@media (min-width: 325px ){
    .menu__item {
        padding: 0.25rem;
        margin: 0 1rem;
        font-size: 1.5rem;
        font-weight: 400;
        border-bottom: 2px solid transparent;
    }
}

@media (min-width: 540px ){
    .built-with {
        display: inherit;
        padding: 0.25rem;
        position: fixed;
        font-size: 12px;
        background-color: #000000;
        color: #FFFFFF;
        font-size: 0.875rem;
    }

    nav {
        justify-content: flex-end;
        background-color: rgba(151,208,225, 0.75);
    }
}