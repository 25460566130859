@font-face {
    font-family: 'Monda';
    font-style: normal;
    font-weight: 100;
      src: url('../libs/fonts/Monda-Regular.ttf') format('truetype');
  }

  @font-face {
    font-family: 'PaytoneOne';
    font-style: normal;
    font-weight: 100;
      src: url('../libs/fonts/FjallaOne-Regular.ttf') format('truetype');
  }

  body, h1, h2, h3, h4, h5, h6, p, ol, ul {
	font-family: 'FjallOne', sans-serif;
  font-weight: 300;
  font-size: 1.125rem;
}

  body, h1, h2, h3, h4, h5, h6, p, ol, ul {
	font-family: 'Monda', sans-serif;
  font-weight: 250;
  margin: 0;
}
 
h1 {
	font-weight: 900;
}

a {
 color: #000;
 text-decoration: none;
}