.portfolio-wrapper {
    flex: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    margin: 0 auto;
}

.portfolio-page {
    width: 90%;
    margin: 0 auto;
    min-height: 100vh;
    display: flex;
    flex: 0 1 auto;
    justify-content: center;
}

.portfolio-title {
    font-family: 'FjallaOne', sans-serif;
    font-size: 3.5rem;
    font-weight: bold;
    line-height: 4rem;
    padding-bottom: 1rem;
    text-align: left;
    margin-top: 4rem;
}

.portfolio-container {
    margin: 0 auto;
    margin-top: 7%;
}

.project-box {
    display: inline-flex;
    flex-wrap: wrap;
    box-sizing: inherit;
    padding: .875em;
    margin: 12px;
    width: 15rem;
    border-radius: inherit;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    background-color: #FFF;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.box-image {
    width: 100%;
    margin: .5em;
}
.box-image-style {
    border-radius: 5px;
}

.read-more-div {
    width: 100%;
}

.read-more-p {
    padding: 0;
    margin: 0;
    font-size: 1rem;
}

.read-more-wrap {
    padding: 0;
    margin-top: .5em;
    margin-bottom: .5em;
    text-align: left;
}

.read-more-state {
    display: none;
  }
  
  .read-more-target {
    opacity: 0;
    max-height: 0;
    font-size: 0;
    transition: .25s ease;
    margin: 0;
  }
  
  .read-more-state:checked ~ .read-more-wrap .read-more-target {
    opacity: 1;
    font-size: 1rem;
    max-height: 999em;
  }
  
  .read-more-state ~ .read-more-trigger:before {
    content: '▼';
  }
  
  .read-more-state:checked ~ .read-more-trigger:before {
      text-align: center;
    content: '▲';
  }
  
  .read-more-trigger {
    cursor: pointer;
    display: inline-block;
    padding: 0 .2em;
    color: #666;
    font-size: .6em;
    border: 1px solid #ddd;
    border-radius: .25em;
    width: 100%;
  }

  .read-more-trigger:hover {
    color: #FFF;
    background-color: #000;
  }

  .portfolio-text {
    font-size: 1.125rem;
  }


.leaflet-img {
    vertical-align: super;
}

.tech-icons {
    display: inline-flex;
    justify-content: space-evenly;
    height: 4em;
    width: 100%;
    margin: 0 auto;
    margin-top: 10%;
    color: #000;
}

.box-title {
    text-align: center;
    width: 100%
}
  

@media (min-width: 735px) {

    .project-box {
        width: 18em;
    }

    .portfolio-wrapper {
        width: 80%;
    }

    .portfolio-title {
        margin-top: 0;
    }

    .portfolio-container {
        width: 100%;
    }

    .read-more-p {
        font-size: .875rem;
    }

    .read-more-state:checked ~ .read-more-wrap .read-more-target {
        font-size: .875rem;
      }
    
    
}


@media (min-width: 325px) {

    .project-box {
        width: 18rem;
    }
    
    
}
